import React, { useRef, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Header from "../../components/Header/Header.tsx";
import Box from "@mui/material/Box";
import ServiceHeader from "./ServiceHeader/ServiceHeader.tsx";
import Membership from "./Membership/Membership.tsx";
import Assessment from "./Assessment/Assessment.tsx";
import { useParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer.tsx";
import { ServiceHeaderAtom } from "../../recoil/ServicesAtoms/ServicesHeaderAtom.ts";
import { useRecoilState } from "recoil";
import { MembershipAtom } from "../../recoil/ServicesAtoms/MembershipAtom.ts";
import { ServicesAtom } from "../../recoil/ServicesAtoms/ServicesAtom.ts";
import { ref, onValue, update } from "firebase/database";
import fire from "../../firebase/Firebase.ts";
import { FooterAtom } from "../../recoil/FooterRecoilAtoms/FooterAtom.ts";
import { TopbarAtom } from "../../recoil/TopbarAtoms/TopbarRecoilAtom.ts";
import AlertMessage from "../../components/AlertMessage/AlertMessage.tsx";

function Services() {
  const { scroll } = useParams();
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [serviceHeaderDetails, setServiceHeaderDetails] = useRecoilState(ServiceHeaderAtom);
  const [scrolled, setScrolled] = useState(false);
  const [membershipDetails, setMembershipDetails] = useRecoilState(MembershipAtom);
  const [servicesDetails, setServicesDetails] = useRecoilState(ServicesAtom);
  const [footerDetails, setFooterDetails] =
      useRecoilState(FooterAtom);
  const [topbarDetails, setTopbarDetails] =
    useRecoilState(TopbarAtom);


  useEffect(() => {
    if (Object.entries(serviceHeaderDetails)?.length === 0) {
      getHeaderData();
    }else if(servicesDetails?.length === 0){
      getServicesData();
    }else if(Object.entries(membershipDetails)?.length === 0){
      getMembershipData();
    }else if (Object.entries(footerDetails)?.length === 0) {
      getFooterData();
    }else if (Object.entries(topbarDetails)?.length === 0) {
      getTopbarData();
    } 
    else{
      setLoader(false);
    }
  }, [serviceHeaderDetails, servicesDetails, membershipDetails, topbarDetails, footerDetails]);

  useEffect(() => {
    if(servicesDetails?.length > 0){
      for(let i=0; i < servicesDetails?.length; i++){
        console.log('scroll is : ', scroll?.toLocaleLowerCase(), ' and heading is : ', servicesDetails[i]?.heading?.toLocaleLowerCase())
        if(scroll?.toLocaleLowerCase() === servicesDetails[i]?.heading?.toLocaleLowerCase()){
          console.log('in scroll con')
          handleScroll(servicesDetails[i]?.id)
        }
      }
    }
  },[servicesDetails, loader])
 
  const handleScroll = id => {
    console.log('in handleScroll');
    console.log(`component id is : component-${id}`)
    const element: any = document.getElementById(`component-${id}`);
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
      offsetTop: -134,
    });
    setScrolled(!scrolled); 
  };

  const getTopbarData = async () => {
    setLoader(true);
    try {
      const dataRef = ref(fire, "topbar");
      onValue(dataRef, (snapshot) => {
        const data = snapshot.val();
        setTopbarDetails(data);
        console.log('topbar data is fetched', data);
      });
    } catch (err) {
      console.log("error in get topbar data is : ", err);
      setAlertMessage("There is problem in fetching topbar data");
      setIsError(true);
      setOpen(true);
      setLoader(false);
    }
  };

  const getFooterData = async () => {
    setLoader(true);
    try {
      const dataRef = ref(fire, "footer");
      onValue(dataRef, (snapshot) => {
        const data = snapshot.val();
        setFooterDetails(data);
        setLoader(false);
      });
    } catch (err) {
      console.log("error in get footer data is : ", err);
      setAlertMessage("There is problem in fetching footer data");
      setIsError(true);
      setOpen(true);
      setLoader(false);
    }
  };

  const getHeaderData = async () => {
    setLoader(true);
    try {
      const dataRef = ref(fire, "service/serviceHeader");
      onValue(dataRef, (snapshot) => {
        const data = snapshot.val();
        setServiceHeaderDetails(data);
        console.log('service header data is fetched', data);
      });
    } catch (err) {
      console.log("error in get service header data is : ", err);
      setAlertMessage("There is problem in fetching header data");
      setIsError(true);
      setOpen(true);
      setLoader(false);
    }
  };

  const getServicesData = async () => {
    setLoader(true);
    try {
      const dataRef = ref(fire, "service/services");
      onValue(dataRef, (snapshot) => {
        const data = snapshot.val();
        setServicesDetails(data);
        console.log('services data is fetched', data);
      });
    } catch (err) {
      console.log("error in get services data is : ", err);
      setAlertMessage("There is problem in fetching services data");
      setIsError(true);
      setOpen(true);
      setLoader(false);
    }
  };

  const getMembershipData = async () => {
    setLoader(true);
    try {
      const dataRef = ref(fire, "service/membership");
      onValue(dataRef, (snapshot) => {
        const data = snapshot.val();
        setMembershipDetails(data);
        console.log('membership data is fetched', data);
      });
    } catch (err) {
      console.log("error in get membership data is : ", err);
      setAlertMessage("There is problem in fetching membership data");
      setIsError(true);
      setOpen(true);
      setLoader(false);
    }
  };

  return (
    <div>
      {!loader ? (
        <>
          <Header from="SERVICES" />
          <ServiceHeader />
          <Membership />
          {servicesDetails?.length > 0 && servicesDetails?.map((item, index) => {
            return(
              <span id={`component-${item?.id}`}>
                <Assessment serviceDetail={item}/>
              </span>
            )
          })}
          <Footer />
        </>
      ) : (
        <div className="bg-yellow loading-div">
          <div className="gray-loading-div">
            {" "}
            <Box className="loading-box">
              <CircularProgress sx={{ color: "red" }} />
            </Box>
          </div>
        </div>
      )}
      {open && (
        <AlertMessage
          open={open}
          setOpen={setOpen}
          alertMessage={alertMessage}
          isError={isError}
          setIsError={setIsError}
          from="Home"
        />
      )}
    </div>
  );
}

export default Services;
