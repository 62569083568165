import React, { useState } from "react";
import BiodataModal from "../../../components/BiodataModal/BioModal.tsx";
import './Tackling.css';

function TacklingSection({tacklingData}) {

  const [openBiodata, setOpenBiodata] = useState(false);

  const handleCloseBiodataModal = () => {
    setOpenBiodata(false);
  };

  return (
    <div className="w-full mb-20 flex flex-col tacklingSection items-center justify-center">
      <h1 className="font-bebas stories-heading tackling-heading">
        {tacklingData?.heading}
      </h1>
      <div className="w-full sm:w-5/6 flex flex-col sm:flex-row items-center sm:items-start justify-center  tackling-parent">
        <div className="sm:hidden w-full sm:w-1/2 flex items-center justify-center 2xl:justify-end">
          <img
            src={tacklingData?.url}
            className="object-cover tacklingImg"
            alt="tacklingImg"
          />
        </div>
        <div className="w-full sm:w-1/2 flex flex-col items-start justify-start text-start details-part">
          <h1 className="tackling-subHead font-bebas text-start">
            "{tacklingData?.subHeading}"
          </h1>
          <span className="sm:hidden w-full flex items-center justify-center">
            <p className=" underline bio-btn font-poppins"  onClick={() => {
                setOpenBiodata(true);
              }}>
              {tacklingData?.btnName}
            </p>
          </span>
          {/* <pre className="text-start tackling-desc font-poppins">
            {tacklingData.description}
          </pre> */}
          <div className='text-start tackling-desc font-poppins' dangerouslySetInnerHTML={{ __html: `${tacklingData?.description}` }} />
        </div>
        <div className="hidden w-full sm:w-1/2 sm:flex flex-col items-center justify-center 2xl:items-end">
          <span className="flex items-center justify-center flex-col">
            <img
              src={tacklingData?.url}
              className="object-cover tacklingImg"
              alt="tacklingImg"
            />
            <p
              className="underline bio-btn font-poppins"
              onClick={() => {
                setOpenBiodata(true);
              }}
            >
              {tacklingData?.btnName}
            </p>
          </span>
        </div>
      </div>
      {openBiodata && (
        <BiodataModal
          handleCloseBiodataModal={handleCloseBiodataModal}
          biodata={tacklingData?.biodata}
        />
      )}
    </div>
  );
}

export default TacklingSection;
