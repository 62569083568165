import React, { useEffect, useState, useRef } from "react";
import { useRecoilState } from "recoil";
import './ArticlesSection.css';
import { ArticlesAtom } from "../../../recoil/ArticlesAtoms/ArticlesAtom.ts";
import { ArticlesHeaderAtom } from "../../../recoil/ArticlesAtoms/ArticlesHeaderAtom.ts";
import Article from "./Article/Article.tsx";
import Pagination from "./Pagination/Pagination.tsx";

function ArticlesSection() {
  
  const [activeCategory, setActiveCategory] = useState('ALL');
  const [articlesDetails, setArticlesDetails] = useRecoilState(ArticlesAtom);
  const [articlesHeaderDetails, setArticlesHeaderDetails] = useRecoilState(ArticlesHeaderAtom);
  const [articlesList, setArticlesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();
  const [firstArticle, setFirstArticle] = useState<number>();
  const [lastArticle, setLastArticle] = useState<number>();
  const scrollToRef = useRef(null);

  useEffect(()=>{
    setArticlesList(articlesDetails);
    divideArticles(articlesDetails);
  },[])

  const divideArticles = (list) => {
    let pages =  Math.ceil(list?.length / 9);
    setTotalPages(pages);
    setFirstArticle(0);
    setLastArticle(8);
    console.log('num of articles: ', list?.length, ' and pages are: ', pages);
  }

  const filterArticles = (searchInput) => {
    if(searchInput === 'ALL'){
        setArticlesList(articlesDetails);
        divideArticles(articlesDetails);
    }else{
        const searchedData = articlesDetails?.filter(
            (data) =>
              data?.category?.toLowerCase()?.includes(searchInput?.toLowerCase())
          );
        setArticlesList(searchedData);
        divideArticles(searchedData);
    }
  }

  const onChangePage = (page) => {
    setCurrentPage(page);
    const fArticle = (page-1)*9;
    const lArticle = fArticle+8;
    setFirstArticle(fArticle);
    setLastArticle(lArticle);
    scrollToComponent();
    console.log('first article: ', fArticle, ' and lArticle: ', lArticle);
  }

  const scrollToComponent = () => {
    if (scrollToRef?.current) {
      scrollToRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        // offsetTop: -200,
      });
    }
  };

  return (
    <div className="w-full flex flex-col items-center">
        <div className='w-full h-20 flex items-center justify-start sm:pl-5 shadow-md' ref={scrollToRef}>
        <p className={`menu-text ml-5 cursor-pointer sm:ml-10 font-bebas ${activeCategory === 'ALL' && 'active-cat'}`} onClick={()=>{setActiveCategory('ALL'); filterArticles('ALL')}}>ALL</p>
            {articlesHeaderDetails?.blogTypes?.map((item, index) => {
                return(
                    <p className={`menu-text ml-5 cursor-pointer sm:ml-10 font-bebas ${activeCategory === item && 'active-cat'}`} onClick={()=>{setActiveCategory(item); filterArticles(item)}}>{item}</p>
                )
            })}
        </div>
        <div className='w-full flex flex-wrap items-center sm:items-start justify-center sm:justify-start my-7 px-3.5' >
            {articlesList?.length > 0 && firstArticle !== undefined && lastArticle !== undefined && articlesList?.length > 0 ? articlesList?.map((item, index) => {
                return(
                    (index >= firstArticle) && (index <= lastArticle) && 
                    <Article details={item}/>
                )
            }) : <div className='w-full h-full flex items-center justify-center'><div className='pt-10 sm:pt-32 flex flex-col items-start'>
               <h1 className='font-bebas text-black text-2xl md:text-4xl font-bold'>NO MATCHES FOUND</h1>
               <p className='font-poppins text-gray-400 text-xs md:text-sm'>Please try another category</p>
              </div></div>}
        </div>
        <div className='w-full sm:h-20 sm:mt-8 mb-10 sm:mb-20 flex items-center justify-center'>
          <Pagination currentPage={currentPage} totalPages={totalPages} onChangePage={onChangePage}/>
        </div>
    </div>
  );
}

export default ArticlesSection;
