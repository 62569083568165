import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import { images } from "../../assets/Images.ts";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import "./Header.css";
import { useRecoilState } from "recoil";
import { TopbarAtom } from "../../recoil/TopbarAtoms/TopbarRecoilAtom.ts";

const pages = [
  { id: 1, label: "HOME", link: "/" },
  { id: 2, label: "SERVICES", link: "/services" },
  { id: 3, label: "RESULTS", link: "/results" },
  { id: 6, label: "ABOUT", link: "/about" },
  { id: 7, label: "ARTICLES", link: "/articles" },
];

function Header(props: any) {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const [topbarDetails, setTopbarDetails] =
    useRecoilState(TopbarAtom);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event?.currentTarget);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event?.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenForm = () => {
    window.open( 
      `${topbarDetails?.buttonLink}`, "_blank");
  }

  return (
    <AppBar
      position="fixed"
      className="bio-details"
      sx={{
        background: "#F5F5F5",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        overflow: "hidden",
        height: "134px",
      }}
    >
      <Container
        maxWidth="xl"
        className="bio-details"
        sx={{ overflow: "hidden" }}
      >
        <Toolbar
          disableGutters
          className="bio-details"
          sx={{ overflow: "hidden" }}
        >
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "BEBASNEUE",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img src={topbarDetails?.logo} alt="logo" className="py-10 w-72" />
          </Typography>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img
              src={topbarDetails?.logo}
              alt="mobile-logo"
              // className="mobileLogo"
              className="py-10 w-64 -ml-6"
            />
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex", marginLeft: "auto" },
            }}
          >
            <Box className="flex items-center justify-center ml-auto">
              {pages.map((page) => (
                <Link to={page?.link} key={page?.id}>
                  {" "}
                  <Button
                    key={page?.id}
                    onClick={handleCloseNavMenu}
                    sx={{
                      textTransform: "none",
                      fontWeight: "bold",
                      fontFamily: "BEBASNEUE",
                      display: "block",
                      color: `${
                        props?.from === page?.label ? "#EA1717" : "black"
                      }`,
                      paddingTop: "30px",
                      fontSize: "24px",
                      paddingBottom: "30px",
                      paddingLeft: "7px",
                      paddingRight: "7px",
                      "&:hover": { boxShadow: "none" },
                    }}
                  >
                    {page?.label}
                  </Button>
                </Link>
              ))}
              <button className="new-services-header-btn font-bebas ml-10" onClick={handleOpenForm} style={{marginTop: '35px'}}>{topbarDetails?.buttonText}</button>
            </Box>
          </Box>
          <Box
            className="bio-details"
            sx={{
              flexGrow: 1,
              overflowY: "hidden",
              display: { xs: "flex", md: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              // color="black"
              sx={{ marginLeft: "auto" }}
            >
              <img src={images?.menuIcon} className="w-7 h-5" alt="menu-icon" />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              className="bio-details"
              sx={{
                display: {
                  xs: "block",
                  md: "none",
                  marginLeft: "25px",
                  overflowY: "hidden",
                  height: "103%",
                },
              }}
            >
              <img
                src={images?.crossBtn}
                className="w-5 h-5 absolute right-0 top-0 mt-5 mr-5 cursor-pointer"
                alt="cross-btn"
              />
              {pages?.map((page: any) => (
                <MenuItem key={page?.id} onClick={handleCloseNavMenu}>
                  <Link to={page?.link}>
                    {" "}
                    <Typography
                      textAlign="center"
                      sx={{
                        text: "black",
                        color: `${
                          props?.from === page?.label ? "#EA1717" : "black"
                        }`,
                        fontWeight: "bold",
                        fontFamily: "BEBASNEUE",
                        fontSize: "24px",
                        paddingLeft: "5px",
                        paddingTop: `${
                          page?.label === "HOME" ? "100px" : "0px"
                        }`,
                      }}
                    >
                      {page?.label}
                    </Typography>
                  </Link>
                </MenuItem>
              ))}
              <Box
                className="h-screen mt-8 bio-details"
                sx={{ width: "280px", overflowY: "hidden" }}
              >
                <button className="new-services-btn font-bebas ml-5" onClick={handleOpenForm}>{topbarDetails?.buttonText}</button>
              </Box>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
