import React from 'react';
import './App.css';
import CustomRouter from './routes/Router.tsx';
import MetaPixel from './utils/meta/metaPixel.tsx';

function App() {

  return (
    <div className="App">
      <MetaPixel />
      <CustomRouter />
    </div>
  );
}

export default App;
