import React from "react";
import { ServiceHeaderAtom } from "../../../recoil/ServicesAtoms/ServicesHeaderAtom.ts";
import { useRecoilState } from "recoil";
import { ArticlesHeaderAtom } from "../../../recoil/ArticlesAtoms/ArticlesHeaderAtom.ts";

function ArticlesHeader() {
  const [articlesHeaderDetails, setArticlesHeaderDetails] =
    useRecoilState(ArticlesHeaderAtom);
 
    const styles = {
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.26),rgba(0, 0, 0, 0.26)) , url("${articlesHeaderDetails?.backgroundImage}")`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat, repeat',
    };
    const mobileStyles = {
      
      backgroundPosition: '50% 0px',
    };

    const getStyles = () => {
      const width = window?.innerWidth;
  
      if (width < 480) {
        return { ...styles, ...mobileStyles };
      } else {
        return styles ;
      }
    };
  return (
    <div className="w-full serviceHeader-bg flex items-center justify-center mt-32" style={getStyles()}>
      <div className='w-full sm:w-5/6 md:w-3/5 lg:w-3/5 xl:w-1/2 2xl:w-1/3 flex items-center justify-center'>
      <h1 className="sm:hidden px-20 sm:px-24 font-bebas text-6xl sm:text-8xl text-white tracking-wider sm:pt-20 slider-heading-service ">
        {articlesHeaderDetails?.sectionHeading}
      </h1>
      <h1 className=" hidden sm:flex font-bebas text-6xl sm:text-8xl text-white tracking-wider sm:pt-20 slider-heading-service ">
       {articlesHeaderDetails?.sectionHeading}
      </h1>
      </div>
    </div>
  );
}

export default ArticlesHeader;
