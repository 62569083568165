import React,{useState, useEffect} from "react";
import "./Fitness4Section.css";

function Fitness4Section(props: any) {
  const { data } = props;
  const [headingArr, setHeadingArr] = useState([]);
  useEffect(()=>{
    const arr = data?.heading1?.split(' ');
    setHeadingArr(arr);
  },[])
  return (
    <div className="w-full flex items-center justify-center">
      <div className="lg:ml-12 parent-div-4 flex flex-col md:flex-row items-center justify-start relative">
        <div className="bg-orange-400 img-div-4">
        <img
          src={data?.img}
          className="detail-img-4 object-top object-cover" alt='fitness1' 
        />
        </div>
        <div className="bg-white detail-div-4">
          <h1 className=" font-bold font-bebas detail-heading1-4">
          {headingArr?.length > 0 && headingArr?.map((item, index)=>{
              return(
                index === 2 ? <><span style={{color: '#EA1717'}}>{item}</span>{" "}</> : <>{item}{" "}</>
              )
            })}
          </h1>
          <p className="font-poppins font-semibold mt-2 text-left details-desc-4 mb-2">
            {data?.details}
          </p>
        </div>
        
      </div>
    </div>
  );
}

export default Fitness4Section;
