import React,{useEffect, useState} from "react";
import { Box } from "@mui/system";
import { images } from "../../../assets/Images.ts";
import "./Result.css";
import { Link } from "react-router-dom";
import ResultsModal from "../../../components/ResultsModal/ResultsModal.tsx";
import { FaqsAtom } from "../../../recoil/HomeAtoms/FaqAtom.ts";
import { useRecoilState } from "recoil";
import { HomeResultsAtom } from "../../../recoil/HomeAtoms/ResultsAtom.ts";
import { HomeResultsSliderAtom } from "../../../recoil/HomeAtoms/HomeResultsSlidersAtom.ts";

function Results({from}) {

  const [openResultsdata, setOpenResultsdata] = useState(false);
  const [modalResultData, setModalResultData] = useState<any>();
  const [allDataList, setAllDataList] = useState<any>([]);
  const [homeResultsDetails, setHomeResultsDetails] =
    useRecoilState(HomeResultsAtom);
    const [homeResultsSliderDetails, setHomeResultsSliderDetails] =
    useRecoilState(HomeResultsSliderAtom);
  // useEffect(() =>{
  //   let tempAllDataList: any = [];
  //   let tempHomeResultsArr: any = [];
  //   for(let i=0;  i < homeResultsDetails?.results?.length; i++){
  //     if(homeResultsDetails?.results[i]){
  //       tempHomeResultsArr.push(homeResultsDetails?.results[i]);
  //     }
  //   }
  //   for(let i=0; i < tempHomeResultsArr?.length; i++){
  //     let obj = {
  //       images: [
  //         tempHomeResultsArr[i].image1,
  //         tempHomeResultsArr[i].image2,
  //         tempHomeResultsArr[i].image3,
  //       ],
  //       heading: tempHomeResultsArr[i].heading,
  //       before: tempHomeResultsArr[i].before,
  //       after: tempHomeResultsArr[i].after
  //     }
  //     tempAllDataList.push(obj);
  //   }
  //   console.log('tempAllDataList is : ', tempAllDataList);
  //   setAllDataList(tempAllDataList);
  // },[])
  const handleCloseResultsdataModal = () => {
    setOpenResultsdata(false);
  };
  // useEffect(()=>{

  // },[allDataList])

  return (
    <div className="my-20 flex flex-col items-center justify-center">
      {from ==='home' && <h1 className="font-bold font-bebas functional-home-headings">{homeResultsDetails?.sectionHeading}</h1> }
      {from  === 'home' && <h2 className="font-bebas mt-5 result-heading-3">
      {homeResultsDetails?.sectionSubHeading}
      </h2> }
      <Box className="">
        <Box className={`h-80 sm:96 ${from === 'home' && 'mt-14'} flex overflow-x-auto result-div`}>
          {homeResultsSliderDetails?.length > 0 && homeResultsSliderDetails?.map((item, index) => {
            return (
              <div className={`shrink-0 shadow-lg w-60 h-72 sm:w-80 sm:h-80 ${index !== 0 && 'ml-7'}`} key={index}>
                  <img
                    src={item?.images[0]}
                    className="object-cover sm:object-contain w-60 h-72 sm:w-80 sm:h-80 cursor-pointer"
                    alt="results1"
                    onClick={() => {
                      setOpenResultsdata(true); setModalResultData(item)
                    }}
                  />
                <div className="description-div w-full flex items-center justify-center">
                  <h1
                    className="text-base lg:text-2xl font-bebas font-semibold"
                    style={{ letterSpacing: "0.01em" }}
                  >
                    {item?.heading}
                  </h1>
                </div>
              </div>
            );
          })}
          
        </Box>
      </Box>
      {openResultsdata && (
        <ResultsModal
          handleCloseResultsdataModal={handleCloseResultsdataModal}
          modalResultData={modalResultData}
        />
      )}
    </div>
  );
}

export default Results;
