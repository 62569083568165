import React from "react";
import { Box } from "@mui/system";
import { images } from "../../assets/Images.ts";
import InstagramIcon from "@mui/icons-material/Instagram";
import {Link} from 'react-router-dom';
import { FooterAtom } from "../../recoil/FooterRecoilAtoms/FooterAtom.ts";
import { useRecoilState } from "recoil";

function Footer() {

  const goTo = (url: string) => {
    window?.open(url, '_blank', 'noopener,noreferrer')
  }
  const [footerDetails, setFooterDetails] =
      useRecoilState(FooterAtom);

  return (
    <Box className="" sx={{ background: "#535554" }}>
      <div className="w-full pt-10 flex items-center justify-center">
        <img src={images?.fbIcon} className="w-5 cursor-pointer" alt='fbIcon' onClick={()=>{goTo(`${footerDetails?.fbLink}`)}}/>
        <InstagramIcon className="text-white ml-7 cursor-pointer" sx={{ fontSize: "35px" }} onClick={()=>{goTo(`${footerDetails?.instaLink}`)}}/>
        <img src={images.twitterIcon} className="w-10 ml-7 cursor-pointer" alt='twitterIcon' onClick={()=>{goTo(`${footerDetails?.twitterLink}`)}}/>
      </div>
      <div className="w-full flex items-center jsutify-center flex-col mt-14 sm:mt-20 pb-28 sm:pb-5">
        <div className="w-2/3 md:w-1/2 h-44 flex items-center justify-center">
          <div className="w-1/2 md:w-52 text-left font-poppins">
            <Link to='/about'><p className="text-lg md:text-3xl text-white">About Us</p></Link>
            <Link to='/services'><p className="text-lg md:text-3xl pt-3 text-white">Services</p></Link>
          </div>
          <div className="w-1/2 md:w-52 text-left font-poppins md:ml-20">
          <Link to='/results'><p className="text-lg md:text-3xl text-white">Results</p></Link>
          <Link to='#'><p className="text-lg md:text-3xl pt-3 text-white" onClick={()=>{window.open(`${footerDetails?.contactUsLink}`, "_blank")}}>Contact us</p></Link>
          </div>
        </div>
        <div className="h-28 mt-12 sm:mt-16 -ml-20 text-left font-poppins">
          <p className="text-xs md:text-xl text-white">
            FunctionalPatternsSouthBay © 2022.{" "}
          </p>
          <p className="text-xs md:text-xl pt-3 text-white">
            All rights reserved{" "}
          </p>
          <p className="text-xs md:text-xl pt-3 text-white">Privacy Policy</p>
        </div>
      </div>
    </Box>
  );
}

export default Footer;
