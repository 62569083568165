import React from 'react';
import './Pagination.css';

const Pagination = ({ currentPage, totalPages, onChangePage }) => {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  const handlePreviousPage = () => {
    if (!isFirstPage) {
      onChangePage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (!isLastPage) {
      onChangePage(currentPage + 1);
    }
  };

  const handlePageClick = (page) => {
    if (page !== currentPage) {
      onChangePage(page);
    }
  };

  const renderPageButtons = () => {
    const pageButtons = [];
    if((currentPage === 1 || currentPage === totalPages) && totalPages > 2){
      pageButtons?.push(
        <button
          className={`mx-3 flex items-center justify-center pagination-button`}
          // onClick={() => handlePageClick(page)}
        >
          ...
        </button>
      );
    }
    if(currentPage > 2 && currentPage < totalPages){
      pageButtons?.push(
        <button
          className={`mx-3 flex items-center justify-center pagination-button`}
          // onClick={() => handlePageClick(page)}
        >
          ...
        </button>
      );
    }
    for (let page = 2; page < totalPages; page++) {
      if (page === currentPage) {
        pageButtons?.push(
          <button
            key={page}
            className={`mx-3 flex items-center justify-center font-bebas pagination-button ${page === currentPage ? 'active' : ''}`}
            onClick={() => handlePageClick(page)}
          >
            {page}
          </button>
        );
      }
    }
    if(currentPage < totalPages-1 && currentPage > 1){
      pageButtons?.push(
        <button
          className={`mx-3 flex items-center justify-center pagination-button`}
        >
          ...
        </button>
      );
    }

    return pageButtons;
  };

  return (
    <div className="pagination flex items-center justify-center">
      {totalPages > 1 && <>
      <button
      key={currentPage-1}
        className="mx-3 pagination-button prev-btn font-bebas"
        onClick={handlePreviousPage}
        disabled={isFirstPage}
      >
        Previous
      </button>
      <button
        className={`mx-3 flex items-center justify-center pagination-button font-bebas ${
          currentPage === 1 ? "active" : ""
        }`}
        onClick={() => handlePageClick(1)}
      >
        1
      </button>
      {renderPageButtons()}
      <button
        className={`mx-3 flex items-center justify-center pagination-button font-bebas ${
          currentPage === totalPages ? "active" : ""
        }`}
        onClick={() => handlePageClick(totalPages)}
      >
        {totalPages}
      </button>
      <button
        key={currentPage+1}
        className="mx-3 pagination-button next-btn active font-bebas"
        onClick={handleNextPage}
        disabled={isLastPage}
      >
        Next
      </button>
      </>}
    </div>

  );
};

export default Pagination;
