import React, { useState, useRef, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import "./Accordian.css";

interface IAccordian {
  id: number;
  content: string;
  title: string;
  content2: string;
  setOpenedQa: any;
  openedQa: number;
}

const Accordian: React.FC<IAccordian> = (props) => {
  const { id, title, content, content2, setOpenedQa, openedQa } = props;
  const [active, setActive] = useState(false);
  const [height, setHeight] = useState("0px");
  const [rotate, setRotate] = useState("transform duration-700 ease");
  const contentSpace = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setHeight(
      openedQa !== id
        ? "0px"
        : `${
            contentSpace?.current !== null && contentSpace?.current?.scrollHeight
          }px`
    );
    setRotate(
      openedQa !== id
        ? "transform duration-700 ease"
        : "transform duration-700 ease rotate-180"
    );
    console.log("openedQa is ", openedQa);
  }, [openedQa]);

  function toggleAccordion() {
    if (!active) {
      setOpenedQa(id);
    } else {
      setOpenedQa(-1);
    }
    setActive(!active);
  }

  return (
    <div className="flex flex-col rounded ">
      <button
        className={`py-3 px-9 box-border appearance-none cursor-pointer focus:outline-none flex items-center justify-between ${
          active && "border-b"
        } border-white`}
        onClick={toggleAccordion}
      >
        <h1
          className={`inline-block font-bebas font-bold acordian-head ${
            openedQa === id && "text-red-600"
          }`}
        >
          {title}
        </h1>
        {openedQa === id ? <RemoveIcon /> : <AddIcon />}
      </button>
      <div
        ref={contentSpace}
        style={{ maxHeight: `${height}` }}
        className=" transition-max-height duration-500 ease-in-out rounded overflow-hidden px-9 sm:pr-16 font-semibold"
      >
        <p className="pb-2 pt-11 font-poppins text-justify pre-section accordian-content">
          {content}
          <br />
          <br />
          {content2}
        </p>
      </div>
    </div>
  );
};

export default Accordian;
